import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PageLayout from "../components/PageLayout";
import PageHeader from "../components/PageHeader";
import { Container, Row, Col, Image } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import instance from "../utilities/axios.js";
import CustomPopup from "../components/CustomPopup";
import { Trans } from "react-i18next";
import MobilePaymentStepper from "../components/MobilePaymentStepper";
import { goTo, submitAsForm } from "../utilities/utils";
import {
  PAYMENT_METHOD_PAYPAL,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_ALIPAY_QR,
  PAYMENT_METHOD_WX_NATIVE,
  PAYMENT_METHOD_APPLE_PAY,
  PAYMENT_METHOD_GOOGLE_PAY,
  PaymentMethodType,
  PAYMENT_METHOD_ONE_TIME_PAYMENT_LINK,
} from "../utilities/constants";
import AppPayComponent from "../components/AppPayComponent";
import { constants } from "../redux/constants";
import { proxyApi } from "../utilities/proxy-api";
import { ApiRequest } from "../models/proxy-api-request";
import { toFriendlyErrorMessage } from "../utilities/portaone-utils";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { updateShopObject } from "../redux/actions/shopActions";
import { api } from "../utilities/api";

const isBrowser = typeof window !== "undefined";

const Checkout = ({ location }) => {
  let shopDetails = useSelector((state: RootState) => state.shop);
  const dispatch = useDispatch();
  const dispatchShop = (obj) => dispatch(updateShopObject(obj) as any);
  const isCanada = shopDetails.country === constants.CANADA;

  const { t } = useTranslation();

  // // debugging
  // if (!shopDetails.oa_paId && location.href.includes("localhost")) {
  //   shopDetails.oa_paId = "141070";
  //   shopDetails.isPostpaid = true;
  //   shopDetails.country = "Canada";
  // }

  const [qrCode, setQrCode] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [isAlipay, setIsAlipay] = useState(true);
  const [iotPayId, setIotPayId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const urlSearchParams = new URLSearchParams(location.search);
  const searchParamString = Array.from(urlSearchParams)
    .filter(([key]) => key !== "planid")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  const paymentThxUrl =
    location.origin +
    "/payment-thank-you" +
    (searchParamString ? "?" + searchParamString : "");
  const cancelUrl =
    typeof window === "undefined"
      ? ""
      : location.href.split("?")[0] + ( searchParamString ? "?" + searchParamString : "");

  let intervalId;
  useEffect(() => {
    const intervalId = setInterval(() => checkStatus(), 1000);
    return () => clearInterval(intervalId);
  }, [iotPayId]);

  const checkStatus = () => {
    if (iotPayId) {
      instance
        .get("Billing/GetIotPayStatus?payId=" + iotPayId)
        .then((response) => {
          if (response.data != "Approved") {
            return;
          }
          setVisibility(false);
          setIotPayId("");
          goTo(paymentThxUrl);
        })
        .catch(() => {
          clearInterval(intervalId);
        });
    }
  };

  const handlePayment = (paymentMethod: string) => {
    // set payment method for usage later on thank you page
    dispatchShop({ payment_method: paymentMethod });
    dispatchShop({ paymentMade: true });

    setErrorMessage("");

    // do dataLayer push

    // add payment info
    // ask trena digital about this if it's incorrect.
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "add_payment_info",
      value: shopDetails.planId,
      ecommerce: {
        payment_type: paymentMethod,
        items: [
          {
            item_name: shopDetails.data,
            item_id: shopDetails.planId,
            price: shopDetails.plan_fee,
            item_category: !isCanada
              ? "USA Prepaid"
              : shopDetails.isPostpaid
              ? "Canada Postpaid"
              : "Canada Prepaid",
            item_variant: shopDetails.planTypeD,
            index: 0,
          },
        ],
      },
    });

    if (shopDetails.country === constants.CANADA) {
      if (!shopDetails.oa_paId) {
        alert("Shopping info is not available for payment.");
        return;
      }

      if (paymentMethod === PAYMENT_METHOD_ONE_TIME_PAYMENT_LINK) {
        if (
          new URLSearchParams(window.location.search).get("bn") &&
          !isNaN(Number(new URLSearchParams(window.location.search).get("bn")))
        ) {
          api
            .sendOneTimePaymentLink(
              Number(new URLSearchParams(window.location.search).get("bn")),
              Number(shopDetails.oa_paId),
              shopDetails.isPostpaid ? "postpaid" : "prepaid"
            )
            .then((response) => {
              if (response.data.success) {
                alert(
                  "Email sent successfully to customer's email address: " +
                    shopDetails.email
                );
              } else if (response.data.message) {
                alert(response.data.message);
              } else if (response.data.error) {
                alert(response.data.error);
              } else {
                alert("Error sending email: Unknown error.");
              }
            })
            .catch((error) => {
              alert("Error sending email: Unknown error.");
              console.error(error);
            });
        }
        return;
      }

      notification.open({
        message: "Info",
        description: "Please wait a moment.",
      });

      // creditcard, paypal, wechat pay and alipay
      const headerKey = shopDetails.isPostpaid ? "oaid" : "paid";
      instance.defaults.headers.post[headerKey] =
        shopDetails.oa_paId.toString();

      if (paymentMethod === PAYMENT_METHOD_WX_NATIVE) {
        setIsAlipay(false);
      } else if (paymentMethod === PAYMENT_METHOD_ALIPAY_QR) {
        setIsAlipay(true);
      }

      const postpaidUrl = `/Activation/PostpaidActivationBilling?billtype=${paymentMethod}&return_url=${encodeURIComponent(
        paymentThxUrl
      )}&cancel_url=${encodeURIComponent(cancelUrl)}`;
      const prepaidUrl = `/Activation/PrepaidActivationBilling?billtype=${paymentMethod}&return_url=${encodeURIComponent(
        paymentThxUrl
      )}&cancel_url=${encodeURIComponent(cancelUrl)}&currency=${
        shopDetails.currency
      }`;

      const url = shopDetails.isPostpaid ? postpaidUrl : prepaidUrl;
      instance
        .post(url)
        .then((response) => {
          let data: string = response.data;
          if (data.startsWith("Failed:")) {
            if (data.endsWith("already paid?")) {
              alert(
                "It looks like you may have already paid for the service! Click OK to refresh."
              );
              goTo(paymentThxUrl);
            }
          } else if (
            paymentMethod === PAYMENT_METHOD_CREDIT_CARD ||
            paymentMethod === PAYMENT_METHOD_PAYPAL
          ) {
            let url: string;
            if (data.includes("?")) {
              url = data + "&" + searchParamString;
            } else {
              url = data + "?" + searchParamString;
            }
            window.location.href = url;
          } else {
            // wechat pay and alipay
            let qrArr = data.split("^");
            if (qrArr.length == 2) {
              const iotPayId = qrArr[0];
              let qrCodeString = qrArr[1];
              setIotPayId(iotPayId);
              setQrCode(qrCodeString);
              setVisibility(true);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (shopDetails.country === constants.USA) {
      switch (paymentMethod) {
        case PAYMENT_METHOD_CREDIT_CARD:
          goTo("/payment");
          break;
        case PAYMENT_METHOD_PAYPAL:
          const payPalTransactionRequest = new ApiRequest({
            amount: shopDetails.total,
            customer_id: shopDetails.us_i_customer,
            return_url: `${location.origin}/us-payment-thank-you?i_payment_transaction={{PayPalTransactionId}}&payment_method=${PAYMENT_METHOD_PAYPAL}`,
            cancel_url: location.href,
          });
          proxyApi.transaction
            .payPal(payPalTransactionRequest)
            .then((res) => {
              console.log(res);
              if (!res.success && res.error) {
                setErrorMessage(toFriendlyErrorMessage(res.error));
                console.error(res.error);
                return;
              }
              // redirects to paypal
              submitAsForm(res.data.pay_url, res.data.payload);
            })
            .catch((err) => {
              console.error(err);
            });
          break;
      }
    }
  };

  const popupCloseHandler = () => {
    setVisibility(false);
    setIotPayId("");
  };

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  return (
    <PageLayout>
      <title>Checkout | PhoneBox</title>
      <PageHeader
        text="Checkout"
        imageSrc="/images/support/pageheader_leaf.png"
      />
      <Container style={{ paddingBottom: "60px", marginTop: "50px" }}>
        <CustomPopup
          onClose={popupCloseHandler}
          show={visibility}
          title={isAlipay ? "Alipay QR Code" : "WeChat QR Code"}
          widthPct={30}
        >
          <Image src={qrCode} />
        </CustomPopup>
        {mobile && (
          <>
            <BackArrow
              onClick={() => {
                goTo("/info-fill-up");
              }}
              style={{
                color: "#000",
                fontWeight: 500,
                fontSize: 20,
                marginLeft: "2rem",
              }}
            >
              {"<"} <Trans>Back</Trans>
            </BackArrow>
            <MobilePaymentStepper
              step3="In Progress"
              step2="completed"
              step1="completed"
            />
          </>
        )}
        <Row>
          <Col sm={12} md={6} className="m-auto">
            <Box>
              <Title>
                <Trans>Payment</Trans>
              </Title>
              <Text>
                <Trans>Please select your payment method</Trans>
              </Text>
              {new URLSearchParams(
                isBrowser ? window?.location?.search : ""
              ).get("bn") &&
              !isNaN(
                Number(
                  new URLSearchParams(
                    isBrowser ? window?.location?.search : ""
                  ).get("bn")
                )
              ) ? (
                <AppPayComponent
                  image="/images/checkout/onetime.png"
                  paymentMethod={PAYMENT_METHOD_ONE_TIME_PAYMENT_LINK}
                  paymentName="One Time Payment Link (sent to customer via email)"
                  width="40px"
                  onClick={() =>
                    handlePayment(PAYMENT_METHOD_ONE_TIME_PAYMENT_LINK)
                  }
                />
              ) : null}
              <AppPayComponent
                image="/images/checkout/credit-card.png"
                paymentMethod={PAYMENT_METHOD_CREDIT_CARD}
                paymentName="Credit Card"
                width="40px"
                onClick={(paymentMethod) => handlePayment(paymentMethod)}
              />
              <AppPayComponent
                image="/images/checkout/paypal.png"
                paymentMethod={PAYMENT_METHOD_PAYPAL}
                paymentName="PayPal"
                width="40px"
                onClick={(paymentMethod) => handlePayment(paymentMethod)}
              />
              {shopDetails.country === constants.CANADA && (
                <>
                  <AppPayComponent
                    image="/images/checkout/alipay.jpg"
                    paymentMethod={PAYMENT_METHOD_ALIPAY_QR}
                    paymentName="AliPay"
                    width="40px"
                    onClick={(paymentMethod) => handlePayment(paymentMethod)}
                  />
                  <AppPayComponent
                    image="/images/checkout/wechat.png"
                    paymentMethod={PAYMENT_METHOD_WX_NATIVE}
                    paymentName="WeChat Pay"
                    width="40px"
                    onClick={(paymentMethod) => handlePayment(paymentMethod)}
                  />
                </>
              )}
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
            </Box>
            {!mobile && (
              <div
                className="text-center mb-5"
                style={{ display: visibility ? "none" : "" }}
              >
                <BackButton
                  onClick={() => {
                    goTo("/info-fill-up");
                  }}
                >
                  <Trans>Back</Trans>
                </BackButton>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Box = styled.div`
  background-color: #f8f8f8;
  border-radius: 20px;
  padding: 30px;
  margin: 40px 0px;
  @media (max-width: 768px) {
    margin: 40px 10px;
    background-color: white;
  }
`;

const Title = styled.p`
  color: #0594ca;
  font-weight: 500;
  font-size: 20px;
`;

const Text = styled.p`
  font-size: 16px;
  color: black;
  font-weight: 500;
  @media (max-width: 410px) {
    font-weight: 700;
    font-size: 15px;
  }
  @media (max-width: 390px) {
    font-weight: 700;
    font-size: 14px;
  }
  @media (max-width: 370px) {
    font-weight: 700;
    font-size: 12px;
  }
`;

const AnnouncementText = styled.p`
  font-size: 16px;
  color: black;
  font-weight: 300;
  @media (max-width: 410px) {
    font-weight: 500;
    font-size: 15px;
  }
  @media (max-width: 390px) {
    font-weight: 500;
    font-size: 14px;
  }
  @media (max-width: 370px) {
    font-weight: 500;
    font-size: 12px;
  }
`;

const BackButton = styled.button`
  font-size: 18px;
  font-weight: 400;
  transition: 0.7s;
  color: #fff;
  margin: 0px;
  outline: none !important;
  padding: 8px 40px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  border: none;
  margin: 15px 0px;
  :hover {
    color: #fff;
  }
`;

const BackArrow = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export default Checkout;

export const Head = () => {
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          function scriptExists() {
            return document.querySelectorAll("script[src='https://www.googletagmanager.com/gtm.js?id=GTM-TJKD8FQ']").length > 0;
          }

          if(!scriptExists()) {
            console.log("does not exist. injecting...");
            (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-TJKD8FQ');
          } 

          var _dcq = _dcq || [];
          var _dcs = _dcs || {};
          _dcs.account = '8316321';
        
          (function() {
            var dc = document.createElement('script');
            dc.type = 'text/javascript'; dc.async = true;
            dc.src = '//tag.getdrip.com/8316321.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(dc, s);
          })();
        `,
        }}
      />
    </>
  );
};
